<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>赛务管理</el-breadcrumb-item>
            <el-breadcrumb-item
                :to="{
                    path: '/individual_manage_homeview/individual_race_management',
                }"
                >个人赛赛务工具</el-breadcrumb-item
            >
            <el-breadcrumb-item>生成喜报</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">喜报信息填写</span>
                </el-col>
            </el-row>
            <el-form
                :model="uploadPosterForm"
                label-width="120px"
                :rules="uploadPosterFormRules"
                ref="uploadPosterFormRef"
            >
                <el-row :gutter="0">
                    <el-col :span="6">
                        <el-form-item label="赛区" prop="districtName">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="uploadPosterForm.districtName"
                                @change="districtChanged"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_district"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.districtName"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="入围赛程" prop="raceSchedule">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="uploadPosterForm.raceSchedule"
                                @change="raceScheduleChanged"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_raceSchedule"
                                    :key="item.id"
                                    :label="item.dictLabel"
                                    :value="item.dictLabel"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="isNational === true">
                        <el-form-item
                            label="区分学校："
                            label-width="200px"
                            prop="separateSchool"
                        >
                            <el-radio-group
                                v-model="uploadPosterForm.separateSchool"
                                @change="separateSchool"
                            >
                                <el-radio label="是"></el-radio>
                                <el-radio label="否"></el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6"  v-if="isNational === true && uploadPosterForm.separateSchool === '否'">
                        <el-form-item label="组别" prop="xibaoGroup">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="uploadPosterForm.xibaoGroup"
                                @change="groupChanged"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_xibao_group"
                                    :key="item.id"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="isNational === false || uploadPosterForm.separateSchool === '是'">
                        <el-form-item label="学校名:" prop="schoolName">
                            <el-input
                                v-model="uploadPosterForm.schoolName"
                                autocomplete="off"
                                placeholder="请输入"
                                style="width: 100%"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="isNational === false">
                        <el-form-item label="学校英文名:" prop="schoolEngName">
                            <el-input
                                v-model="uploadPosterForm.schoolEngName"
                                autocomplete="off"
                                placeholder="请输入"
                                style="width: 100%"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="isNational === false">
                        <el-form-item label="列数:" prop="columnNum">
                            <el-select
                                v-model="uploadPosterForm.columnNum"
                                placeholder="请选择"
                                style="width: 100%"
                            >
                                <el-option label="1" value="1"></el-option>
                                <el-option label="2" value="2"></el-option>
                                <el-option label="3" value="3"></el-option>
                                <el-option label="4" value="4"></el-option>
                                <el-option label="5" value="5"></el-option>
                                <el-option label="6" value="6"></el-option>
                            </el-select> </el-form-item></el-col
                >
                    <el-col :span="6" v-if="isNational === false">
                        <el-form-item
                            label="区分年级："
                            prop="gradesGroup"
                        >
                            <el-radio-group
                                v-model="uploadPosterForm.gradesGroup"
                                @change="changeIsGroup"
                            >
                                <el-radio label="是"></el-radio>
                                <el-radio label="否"></el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                <el-col :span="6"  v-if="isNational === false">
                        <el-form-item
                            label="是否喷绘："
                            prop="isInkjet"
                        >
                            <el-radio-group
                                v-model="uploadPosterForm.isInkjet"
                            >
                                <el-radio label="是"></el-radio>
                                <el-radio label="否"></el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col></el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <span v-if="isNational === false" style="color: red; margin-right: 20px;">注* 上传文件后再修改“区分年级”选项，需要重新上传文件</span>
                <span v-if="isNational === true" style="color: red; margin-right: 20px;">注* 裁判系统生成晋级名单后可拉取信息,再点击生成喜报</span>
                <el-button
                        v-if="isNational === true && uploadPosterForm.separateSchool !== '是'"
                        style="margin-right: 10px; height: 32px;"
                        type="danger"
                        size="small"
                        @click="pullwithOneClick"
                        >一键生成喜报</el-button
                    >
                <el-upload
                    class="upload-demo"
                    ref="uploadPosterRef"
                    :action="this.uploadPosterForm.uploadUrl"
                    :data="{
                        schoolName: this.uploadPosterForm.schoolName,
                        columnNum: this.uploadPosterForm.columnNum,
                        gradesGroup:
                            this.uploadPosterForm.gradesGroup === '是'
                                ? 'true'
                                : 'false',
                        districtName: this.uploadPosterForm.districtName,
                    }"
                    :before-upload="beforeUpload"
                    :on-preview="uploadPosterProgress"
                    :on-success="uploadPosterSuccess"
                    :on-error="uploadPostereError"
                    :on-exceed="uploadPosterExceed"
                    :headers="uploadPosterForm.headerObj"
                    :limit="1"
                    :auto-upload="true"
                >
                    <el-button
                        style="margin-left: 10px"
                        type="primary"
                        size="small"
                        @click="downLoadUploadPoster"
                        >下载模版</el-button
                    >
                    <el-button size="small" type="primary" slot="trigger"
                        >上传文件</el-button
                    >
                    <el-button
                        style="margin-left: 10px"
                        type="success"
                        size="small"
                        @click="generateGoodnewsClicked"
                        >生成喜报</el-button
                    >
                </el-upload>
            </el-row>
        </el-card>
        <!-- 单张喜报生成 -->
        <div
            id="printId"
            class="html2canvasDivShow"
            v-if="showTidingsGladTidings && isGroup === false && isNational === false"
        >
            <div class="pic">
                <img :src="topBackImage" />
            </div>
            <div class="pic4">
                <img class="district_backimg" src="@/assets/img/district_back.png" alt="">
                <div class="class_name">{{ lessongoodNewsDistrictName }}入围{{schduleName}}</div>
            </div>
            <div class="pic3">
                <div class="class_school">{{ lessongoodNewsSchoolName }}</div>
                <div class="class_eng_school">
                        {{ uploadPosterForm.schoolEngName }}
                    </div>
            </div>
            <div :class="uploadPosterForm.isInkjet === '是' ? 'inkjetNameClass' : 'nameList'">
                <div class="multicolumn">
                    <div
                        v-for="(itemList, index) in finalDataList"
                        :key="index"
                        v-bind:style="player_nameclass"
                        style="width: 100%; display: flex; flex-direction: row"
                    >
                        <div style="width: 100%">
                            <div
                                class="multicolumn-item"
                                v-for="(item, itemIndex) in itemList"
                                :key="itemIndex"
                            >
                                <div
                                    v-if="item.gradeName"
                                    class="gradeName_class"
                                >
                                    {{ item.gradeName }}
                                </div>
                                <div v-else class="userName_class">
                                    {{ item }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="index < finalDataList.length - 1"
                            class="itemline"
                        ></div>
                    </div>
                </div>
            </div>
            <div class="pic2">
                <img :src="bottomBackImage" />
            </div>
        </div>
        <!-- 多张海报生成 -->
        <div
            class="manyPrintClass"
            v-if="showTidingsGladTidings && isGroup === true && isNational === false"
        >
            <div
                v-for="(item, index) in batchFinalDataList"
                :key="index"
                :id="`batchprintImage_${index}`"
                class="html2canvasDivShow"
            >
                <div class="pic">
                    <img
                        :src="topBackImage"
                    />
                </div>
                <div class="pic4">
                    <img class="district_backimg" src="@/assets/img/district_back.png" alt="">
                    <div class="class_name">
                        {{ lessongoodNewsDistrictName }}入围{{schduleName}}
                    </div>
                </div>
                <div class="pic3">
                    <div class="class_school">
                        {{ lessongoodNewsSchoolName }}
                    </div>
                    <div class="class_eng_school">
                        {{ uploadPosterForm.schoolEngName }}
                    </div>
                </div>
                <div :class="uploadPosterForm.isInkjet === '是' ? 'inkjetNameClass' : 'nameList'">
                    <!-- 多张 -->
                    <div class="firstPage">{{ item.gradeName }}</div>
                    <div class="multicolumn1">
                        <div
                            v-for="(itemList, index) in item.userName"
                            :key="index"
                            v-bind:style="player_nameclass"
                            style="
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                            "
                        >
                            <div style="width: 100%">
                                <div
                                    class="multicolumn-item"
                                    v-for="(nameitem, itemIndex) in itemList"
                                    :key="itemIndex"
                                >
                                    <div class="userName_class">
                                        {{ nameitem }}
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="index < item.userName.length - 1"
                                class="itemline"
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="pic2">
                    <img
                        :src="bottomBackImage"
                    />
                </div>
            </div>
        </div>
         <!-- 单张国赛喜报生成 -->
         <div
            id="printNationalId"
            class="html2canvasDivShow"
            v-if="showTidingsGladTidings && isNational === true && (uploadPosterForm.separateSchool === '否' || uploadPosterForm.separateSchool === '')"
        >
            <div class="pic">
                <img :src="topBackImage" />
                <div class="district-name-back">
                  <div style="width: 35px;"></div>
                    <div class="district-name">{{ lessongoodNewsDistrictName }}</div>
                    <div class="district-eng">{{lessongoodNewsDistrictEngName}} AREA</div>
                </div>
            </div>
            <div class="pic5">
                <img class="nation_title_backimg" :src="nationTitleImage" alt="">
            </div>
            <div class="pic3">
                <div class="class_school" style="color: #e6b372; font-weight: 800;">晋级名单</div>
                <div class="class_school" style="color: #e6b372; font-weight: 800;">{{ uploadPosterForm.xibaoGroup }}</div>
            </div>
            <div :class="uploadPosterForm.isInkjet === '是' ? 'inkjetNameClass' : 'nameList'">
                <div class="nation-multicolumn">
                    <el-row v-for="(itemList, index) in goodNewsData.list" :key="index" style="margin-top: 8px;" type="flex" align="middle">
                    <el-col :span="4">
                        <div class="rank-class" style="font-size: 17px;">{{itemList.rank}}</div>
                    </el-col>
                    <el-col :span="9">
                        <div class="user-name" style="font-size: 19px;">{{itemList.name}}</div>
                    </el-col>
                    <el-col :span="11">
                        <div class="school-class" style="font-size: 15px;">{{itemList.schoolName}}</div>
                    </el-col>
                </el-row>
                </div>
            </div>
            <div class="pic2">
                <img :src="bottomBackImage" />
            </div>
        </div>
         <!-- 单张国赛喜报生成 区分学校 -->
         <div
            id="printIdschool"
            class="html2canvasDivShow"
            v-if="showTidingsGladTidings && isNational === true && uploadPosterForm.separateSchool === '是'"
        >
            <div class="pic">
                <img :src="topBackImage" />
                <div class="district-name-back">
                   <div style="width: 35px;"></div>
                    <div class="district-name">{{ lessongoodNewsDistrictName }}</div>
                    <div class="district-eng">{{lessongoodNewsDistrictEngName}} AREA</div>
                </div>
            </div>
            <div class="pic5">
                <img class="nation_title_backimg" :src="nationTitleImage" alt="">
            </div>
            <div class="pic3">
                <div class="class_schoolName" style="color: #e6b372; font-weight: 800;">{{ uploadPosterForm.schoolName }}</div>
                <div class="school-name-back">
                  <div class="line-view"></div>
                  <div class="class_school2" style="color: #e6b372; font-weight: 800;">晋级名单</div>
                  <div class="line-view"></div>
                </div>
            </div>
            <div :class="uploadPosterForm.isInkjet === '是' ? 'inkjetNameClass' : 'nameList'">
                <div class="nation-multicolumn">
                    <el-row v-for="(itemList, index) in goodNewsData.list" :key="index" style="margin-top: 20px;" type="flex" align="middle">
                    <el-col :span="5">
                        <div class="rank-class" style="font-size: 17px;">{{itemList.grade}}</div>
                    </el-col>
                    <el-col :span="12">
                        <div class="user-name" style="font-size: 19px;">{{itemList.name}}</div>
                    </el-col>
                    <el-col :span="7">
                        <div class="rank-school-class" style="font-size: 15px;">{{itemList.rank}}</div>
                    </el-col>
                </el-row>
                </div>
            </div>
            <div class="pic2">
                <img :src="bottomBackImage" />
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas'
import * as changedpi from 'changedpi'
import { queryDistrictPage, goodNewsNationalData } from '@/http/api'
import { pinyin } from 'pinyin-pro'
export default {
  data () {
    return {
      showTidingsGladTidings: false,
      isGroup: false,
      isNational: false,
      player_nameclass: {
        width: '100%',
        color: '#e8c77a'
      },
      nationTitleImage: '',
      dict_raceSchedule: this.$userInfo.dict_raceSchedule(),
      dict_xibao_group: this.$userInfo.dict_xibao_group(),
      uploadPosterForm: {
        uploadUrl: this.$http.defaults.baseURL + 'goodNews/importExcel',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        },
        schoolName: '',
        separateSchool: '',
        columnNum: '',
        gradesGroup: '',
        districtName: '',
        districtImage: '',
        isInkjet: '否'
      },
      lessongoodNewsDistrictEngName: '',
      uploadPosterFormRules: {
        separateSchool: [{ required: true, message: '请选择', trigger: 'change' }],
        districtName: [{ required: true, message: '请输入赛区', trigger: 'blur' }],
        schoolName: [{ required: true, message: '请输入学校名', trigger: 'blur' }],
        columnNum: [{ required: true, message: '请选择列数', trigger: 'change' }],
        gradesGroup: [{ required: true, message: '请选择是否分年级', trigger: 'change' }],
        raceSchedule: [{ required: true, message: '请选择入围赛程', trigger: 'change' }],
        xibaoGroup: [{ required: true, message: '请选择组别', trigger: 'change' }]
      },
      lessonArr: {
        oneGradeNames: '一年级',
        twoGradeNames: '二年级',
        threeGradeNames: '三年级',
        fourGradeNames: '四年级',
        fiveGradeNames: '五年级',
        sixGradeNames: '六年级',
        sevenGradeNames: '七年级',
        eightGradeNames: '八年级',
        nineGradeNames: '九年级'
      },
      topBackImage: require('@/assets/img/xibao_top_backview_2024.jpg'),
      bottomBackImage: require('@/assets/img/tidings-bom-imag-new.png'),
      dict_district: [],
      // 单组年级
      lessongoodNewsSchoolName: '', // 学校
      lessongoodNewsDistrictName: '', // 赛区
      schduleName: '',
      finalDataList: [],
      batchFinalDataList: []
    }
  },
  created () {
    this.getDistrictList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    districtChanged (value) {
      for (let index = 0; index < this.dict_district.length; index++) {
        const element = this.dict_district[index]
        if (element.districtName === value) {
          this.uploadPosterForm.districtId = element.id
        }
      }
      if (this.isNational === false) {
        if (value.indexOf('全国非') !== -1) {
          this.lessongoodNewsDistrictName = '全国选区'
        } else {
          this.lessongoodNewsDistrictName = value.replace('赛', '选')
        }
      } else {
        if (value.indexOf('全国非') !== -1) {
          this.lessongoodNewsDistrictName = '全国选区'
          this.lessongoodNewsDistrictEngName = 'NATIONAL NETWORK'
        } else {
          var cutOutStr = value.substring(0, 2)
          this.lessongoodNewsDistrictName = value.replace('赛', '选')
          this.lessongoodNewsDistrictEngName = pinyin(cutOutStr, { toneType: 'none' }).toUpperCase()
        }
      }
    },
    // 是否区分学校
    separateSchool (value) {
      console.log('value', value)
    },
    raceScheduleChanged (value) {
      console.log('value', value)
      this.uploadPosterForm.uploadUrl = this.$http.defaults.baseURL + 'goodNews/importExcel'
      if (value === '城市半决选') {
        this.topBackImage = require('@/assets/img/xibao_top_semi_backview.png')
        this.bottomBackImage = require('@/assets/img/tidings-bom-imag-new.png')
        this.isNational = false
      } else if (value === '全国总决选') {
        this.isNational = true
        this.uploadPosterForm.uploadUrl = this.$http.defaults.baseURL + 'goodNews/nationalImportExcel'
        this.topBackImage = require('@/assets/img/erp-nation-glad-top.png')
        this.nationTitleImage = require('@/assets/img/erp-nation-glad-title.png')
        this.bottomBackImage = require('@/assets/img/erp-nation-glad-bottom.png')
        if (this.lessongoodNewsDistrictName.indexOf('全国') !== -1) {
          this.lessongoodNewsDistrictName = '全国选区'
          this.lessongoodNewsDistrictEngName = 'NATIONAL NETWORK'
        } else {
          this.lessongoodNewsDistrictName = this.lessongoodNewsDistrictName.replace('赛', '选')
          var cutOutStr = this.lessongoodNewsDistrictName.substring(0, 2)
          this.lessongoodNewsDistrictEngName = pinyin(cutOutStr, { toneType: 'none' }).toUpperCase()
        }
        this.isGroup = false
        this.uploadPosterForm.raceScheduleId = 5
      } else {
        this.isNational = false
        this.topBackImage = require('@/assets/img/xibao_top_backview_2024.jpg')
        this.bottomBackImage = require('@/assets/img/tidings-bom-imag-new.png')
      }
      this.schduleName = value
    },
    groupChanged (value) {
      for (let index = 0; index < this.dict_xibao_group.length; index++) {
        const element = this.dict_xibao_group[index]
        console.log('element', element)
        if (element.dictLabel === value) {
          this.uploadPosterForm.level = element.dictCode
        }
      }
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 下载模版
    downLoadUploadPoster () {
      var downLoadUrl = this.$env.baseIP + (this.isNational === true ? 'goodNews/nationalDownLoad' : 'goodNews/downLoad')
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, {})
    },
    // 上传文件前的校验
    beforeUpload () {
      this.$refs.uploadPosterFormRef.validate(async valid => {
        if (!valid) {
          this.$refs.uploadPosterRef.clearFiles()
          return false
        } else {
          return true
        }
      })
    },
    // 一键拉取生成喜报数据
    pullwithOneClick () {
      this.$refs.uploadPosterFormRef.validate(async valid => {
        if (!valid) return
        goodNewsNationalData(this.uploadPosterForm).then((res) => {
          this.showTidingsGladTidings = true
          this.goodNewsData = res.data
          this.$refs.uploadPosterRef.clearFiles()
          this.$forceUpdate()
        //   this.printImage()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    changeIsGroup () {
      this.$refs.uploadPosterRef.clearFiles()
    },
    // 已上传钩子
    uploadPosterProgress () {
    },
    // 上传成功钩子
    uploadPosterSuccess (res) {
      this.showTidingsGladTidings = true
      this.isGroup = res.data.gradesGroup
      this.goodNewsData = res.data
      if (this.isNational === true) { // 国赛喜报数据解析
        this.$forceUpdate()
      } else {
        this.lessongoodNewsSchoolName = res.data.schoolName// 学校
        this.$data.player_nameclass.width = (100 / res.data.columnNum) + '%'
        var obj = this.goodNewsData.goodNewsDetails
        if (!this.goodNewsData.gradesGroup) {
          const finalList = []
          for (const v in obj) { // 将所有数据合并到一个数组中
            finalList.push({ gradeName: this.lessonArr[v] })
            finalList.push.apply(finalList, obj[v])
          }
          const twoDimensionalArray = []
          var rowsNumber = parseInt(finalList.length / this.goodNewsData.columnNum)
          for (let index = 0; index < this.goodNewsData.columnNum; index++) {
            if (finalList.length % this.goodNewsData.columnNum !== 0) {
              if (index < (finalList.length % this.goodNewsData.columnNum)) {
                twoDimensionalArray.push(finalList.slice(index * (rowsNumber + 1), (rowsNumber + 1) * (index + 1)))
              } else {
                twoDimensionalArray.push(finalList.slice(index * (rowsNumber + 1), (index + 1) + ((index + 1) * rowsNumber)))
              }
            } else {
              twoDimensionalArray.push(finalList.slice(index * rowsNumber, (index + 1) * rowsNumber))
            }
          }
          this.finalDataList = twoDimensionalArray
          this.$forceUpdate()
        } else {
          const finalList = [{ gradeName: '', userName: [] }]
          if (this.goodNewsData.gradesGroup) {
            for (const v in obj) {
              if (obj[v].length >= this.goodNewsData.columnNum) {
                var usernamelist = this.averageAssign(obj[v], this.goodNewsData.columnNum)
                finalList.push({ gradeName: this.lessonArr[v], userName: usernamelist })
              } else {
                this.$curUtils.closeLoading(this)
                return this.$message({
                  message: '列数不能大于单年级人数',
                  type: 'warning'
                })
              }
            }
            this.batchFinalDataList = finalList
            this.$forceUpdate()
          }
        }
      }
    },
    // 生成喜报点击
    formPosterClicked () {
      this.formPoster = true
      this.$refs.uploadPosterRef.clearFiles()
    },
    // 生成喜报
    generateGoodnewsClicked () {
      this.$refs.uploadPosterFormRef.validate(async valid => {
        if (!valid) return
        if (this.$refs.uploadPosterRef.uploadFiles.length === 0 && this.showTidingsGladTidings === false) {
          return this.$message.warning('请选择要上传的文件')
        }
        if (this.goodNewsData.gradesGroup) {
          this.formPoster = false
          for (let index = 0; index < this.batchFinalDataList.length; index++) {
            await this.batchprintImage('batchprintImage_' + index)
          }
        } else {
          this.printImage()
          this.formPoster = false
        }
      })
    },
    averageAssign (arr, n) {
      const len = arr.length
      const result = []
      for (let i = 0; i < n; i++) {
        result[i] = []
      }
      for (let i = 0; i < len; i++) {
        result[i % n].push(arr[i])
      }
      return result
    },
    printImage () {
      var box
      if (this.isNational === true) {
        if (this.uploadPosterForm.separateSchool === '是') {
          box = document.getElementById('printIdschool')
        } else {
          box = document.getElementById('printNationalId')
        }
      } else {
        box = document.getElementById('printId')
      }
      if (box) {
        html2canvas(box, {
          scale: 4, // 渲染的比例
          dpi: 500 // 处理模糊问题
        }).then(canvas => {
          const printUrl = canvas.toDataURL('image/png', 1)
          const endprintUrl = changedpi.changeDpiDataUrl(printUrl, 100)
          const eleLink = document.createElement('a')
          eleLink.href = endprintUrl
          eleLink.download = 'pictureName'
          document.body.appendChild(eleLink)
          eleLink.click()
          document.body.removeChild(eleLink)
        })
      }
    },
    printIdschool () {
      var box = document.getElementById('printId')
      if (box) {
        html2canvas(box, {
          scale: 4, // 渲染的比例
          dpi: 500 // 处理模糊问题
        }).then(canvas => {
          const printUrl = canvas.toDataURL('image/png', 1)
          const endprintUrl = changedpi.changeDpiDataUrl(printUrl, 100)
          const eleLink = document.createElement('a')
          eleLink.href = endprintUrl
          eleLink.download = 'pictureName'
          document.body.appendChild(eleLink)
          eleLink.click()
          document.body.removeChild(eleLink)
        })
      }
    },
    batchprintImage (batchprintId) {
      console.log('batchsemiPrintId', batchprintId)
      if (batchprintId !== 'batchprintImage_0') {
        var box = document.getElementById(batchprintId)
        if (box) {
          html2canvas(box, {
            scale: 4, // 渲染的比例
            dpi: 500// 处理模糊问题
          }).then(canvas => {
            const printUrl = canvas.toDataURL('image/png', 1)
            const endprintUrl = changedpi.changeDpiDataUrl(printUrl, 100)
            const eleLink = document.createElement('a')
            eleLink.href = endprintUrl
            eleLink.download = 'pictureName'
            document.body.appendChild(eleLink)
            eleLink.click()
            document.body.removeChild(eleLink)
          })
        }
      }
    },
    // 上传失败钩子
    uploadPostereError (error) {
      this.$message.error(error)
    },
    // 超出后动作
    uploadPosterExceed (files, fileList) {
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
    }
  }
}
</script>

<style lang="less" scoped>
.div_algin_right {
    text-align: right;
}
img {
    width: 100%;
}
.line-view{
  width: 100px;
  height: 1px;
  background-color: #e6b372;
}
.district-name-back{
    display: flex;
    width: 70%;
    margin-left: 40rpx;
    top: -50px;
    font-family: "xique";
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    color: white;
}
.school-name-back{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.district-name{
    text-align: center;
    font-size: 22px;
}
.district-eng{
    text-align: center;
    margin-left: 8px;
    // transform: rotate(90deg);
    // -webkit-transform: rotate(90deg);
    // width: 120px;
    // left: -22px;
    /* width: 30rpx; */
    font-size: 22rpx;
  }
.manyPrintClass {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
// 885.75px
#batchprintId {
    position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background-color: #b81c26;
}
#printId {
    // position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background: radial-gradient(
        circle at center,
        #b81c26,
        #b81c26,
        #b81c26,
        #b81c26
    );
    // background-color: #B81C26;
}
#printNationalId {
  width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background: radial-gradient(
        circle at center,
        #b81c26,
        #b81c26,
        #b81c26,
        #b81c26
    );
}
#printIdschool{
  width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background: radial-gradient(
        circle at center,
        #b81c26,
        #b81c26,
        #b81c26,
        #b81c26
    );
}
#batchprintImage_0 {
    position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background-color: #b81c26;
}
#batchprintImage_1 {
    position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background-color: #b81c26;
}
#batchprintImage_2 {
    position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background-color: #b81c26;
}
#batchprintImage_3 {
    position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background-color: #b81c26;
}
#batchprintImage_4 {
    position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background-color: #b81c26;
}
#batchprintImage_5 {
    position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background-color: #b81c26;
}
#batchprintImage_6 {
    position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background-color: #b81c26;
}
#batchprintImage_7 {
    position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background-color: #b81c26;
}
#batchprintImage_8 {
    position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background-color: #b81c26;
}
#batchprintImage_9 {
    position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background-color: #b81c26;
}
#batchprintImage_10 {
    position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background-color: #b81c26;
}
#batchprintImage_11 {
    position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background-color: #b81c26;
}
#batchprintImage_12 {
    position: relative;
    width: 590.5px;
    min-height: 846px;
    letter-spacing: 1px;
    background-color: #b81c26;
}
.pic {
    position: relative;
    width: 100%;
    height: 286px;
    top: 0;
}
.pic4{
    height: 80px;
    width: 100%;
    top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.pic5{
    height: 130px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.nation_title_backimg{
    width: 320px;
    height: 102px;
}
.district_backimg{
    width: 480px;
    height: 70px;
    z-index: 99;
    position: absolute;
}
.pic3 {
    height: 79.75px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.pic2 {
    width: 100%;
    height: 140px;
    /* background-color: #B81C26; */
}
.pic2 img {
    margin-top: 30px;
    width: 100%;
}
.class_name {
    position: absolute;
    text-align: center;
    width: 246px;
    font-weight: 900;
    font-size: 18px;
    color: #B81C26;
    height: 28px;
    // line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    top: 12px;
    // left: 245px;
}
// .class_name img {
//     height: 100%;
//     width: 15%;
// }
// .class_name div {
//     /* margin: 10px; */
//     position: relative;
//     // margin-top: -14%;
// }
.class_school {
    color: #ffffff;
    text-align: center;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    position: relative;
    // top: -35px;
}
.class_school2{
  color: #ffffff;
    text-align: center;
    margin: 0px 10px;
    font-size: 20px;
    font-weight: 600;
    position: relative;
}
.class_eng_school{
    color: #ffffff;
    text-align: center;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    position: relative;
    // top: -35px;
    margin-bottom: 10px;
}
.nameList {
    /* background-color: green; */
    /* background:linear-gradient(#B81C26,#83191D); */
    width: 100%;
    /* background-color: #B81C26; */
    min-height: 290px;
    text-align: center;
    color: #e8c77a;
    /* position: absolute; */
    /* top: 370px; */
    /* margin-top:600px; */
    /* margin-bottom:300px; */
    /* z-index: 2; */
}
.inkjetNameClass{
    width: 100%;
    height: 290px;
    text-align: center;
    color: #e8c77a;
    max-height: 290px;
}
.class_schoolName{
  color: #ffffff;
    text-align: center;
    width: 100%;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 5px;
    position: relative;
}
.firstPage {
    width: 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 20px;
    /* margin: 20px 0; */
}
/****/
.multicolumn {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    /* align-self: start; */
    justify-content: center;
    /* justify-content: space-evenly; */
    // height: 93%;
}
.multicolumn-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
}
/* .multicolumn > div {
  display: flex;
  justify-content: center;
  width: 56px;
  line-height: 30px;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
} */
.multicolumn_tex1 {
    border-right: solid 1px #ffffff01;
    height: 100%;
    align-self: start;
}
.multicolumn_tex2 {
    /* border: dashed 0.5px #e8ce91;
  height: 100%;
  align-self: start;
  min-height: 185px; */
}
.multicolumn1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* align-self: start; */
    /* justify-content: space-evenly; */
    height: 93%;
}
.html2canvasDivShow {
    margin-top: 20px;
    // top: -100000px;
    // left: -1000000px;
}
.class_promotion_img {
    width: 50%;
    margin-left: 25%;
}

.player-name {
    margin-bottom: 5px;
}
.gradeName_class {
    color: white;
    font-family: "fzbw";
    font-size: 18px;
}
.userName_class {
    font-size: 16px;
}
.itemline {
    border: 1px dashed #e8c77a;
    width: 0px;
    padding: auto;
}
.nation-multicolumn{
    width: 74%;
    margin-left: 16%;
}
.rank-class{
    font-size: 18px;
    color: #e8c77a;
    text-align: center;
}
.rank-school-class{
    font-size: 18px;
    color: #e8c77a;
    text-align: left;
}
.user-name{
    font-size: 20px;
    color: #e8c77a;
    font-weight: 800;
    text-align: center;
}
.school-class{
    font-size: 12rpx;
    color: #fce3a4;
    text-align: left;
}
</style>
